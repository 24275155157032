<template>
  <div class="amazing_feature">
    <div class="container">
      <div class="style">
        <p><span>#2</span> {{ $t("Nima qila olamiz") }}</p>
      </div>
      <div class="row">
        <div class="col-md-6 col-sm-12 col-xs-12">
          <div class="single_feature">
            <div class="feature_icon"><i class="fa fa-cog"></i></div>
            <div>
              <h3>{{ $t("Service 1") }}</h3>
              <p>
                {{ $t("Service 1 Description") }}
              </p>
            </div>
          </div>
        </div>
        <!-- END COL-->
        <div class="col-md-6 col-sm-12 col-xs-12">
          <div class="single_feature">
            <div class="feature_icon"><i class="fa fa-cog"></i></div>
            <div>
              <h3>{{ $t("Service 2") }}</h3>
              <p>
                {{ $t("Service 2 Description") }}
              </p>
            </div>
          </div>
        </div>
        <!-- END COL-->
        <div class="col-md-6 col-sm-12 col-xs-12">
          <div class="single_feature">
            <div class="feature_icon"><i class="fa fa-cog"></i></div>
            <div>
              <h3>{{ $t("Service 3") }}</h3>
              <p>
                {{ $t("Service 3 Description") }}
              </p>
            </div>
          </div>
        </div>
        <!-- END COL-->
        <div class="col-md-6 col-sm-12 col-xs-12">
          <div class="single_feature">
            <div class="feature_icon"><i class="fa fa-cog"></i></div>
            <div>
              <h3>{{ $t("Service 4") }}</h3>
              <p>
                {{ $t("Service 4 Description") }}
              </p>
            </div>
          </div>
        </div>
        <!-- END COL-->
        <div class="col-md-6 col-sm-12 col-xs-12">
          <div class="single_feature">
            <div class="feature_icon"><i class="fa fa-cog"></i></div>
            <div>
              <h3>{{ $t("Service 6") }}</h3>
              <p>
                {{ $t("Service 6 Description") }}
              </p>
            </div>
          </div>
        </div>
        <!-- END COL-->
        <div class="col-md-6 col-sm-12 col-xs-12">
          <div class="single_feature">
            <div class="feature_icon"><i class="fa fa-cog"></i></div>
            <div>
              <h3>{{ $t("Service 5") }}</h3>
              <p>
                {{ $t("Service 5 Description") }}
              </p>
            </div>
          </div>
        </div>
        <!-- END COL-->
      </div>
      <!--- END ROW -->
    </div>
    <!--- END CONTAINER -->
  </div>
</template>

<script >
</script>

<style lang="scss" scoped>
.amazing_feature {
  background: #e8eff9;
  padding-top: 80px;
  padding-bottom: 50px;
}

.style {
  border: 1px solid #95969f;
  border-radius: 20px;
  align-items: center;
  text-align: center;
  padding: 10px 30px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  opacity: 1;
  animation: fadeInUp 1s ease-out forwards;
  animation-delay: 1s;
  margin-bottom: 40px;
}

.style span {
  border: 1px solid #5f73a8;
  padding: 0 10px;
  border-radius: 0 10px;
  text-align: center;
  color: #fff;
  background: #5f73a8;
}

.single_feature {
  background: #fff;
  box-shadow: 0 2px 30px rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
  padding: 40px;
  display: flex;
  height: 200px;
  vertical-align: middle;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
}

.single_feature:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #5f73a8;
  -webkit-transform: scaleY(0);
  transform: scaleY(0);
  -webkit-transform-origin: 50% 0;
  transform-origin: 50% 0;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}

.single_feature:hover,
.single_feature:focus,
.single_feature:active {
  color: white;
}

.single_feature:hover:before,
.single_feature:focus:before,
.single_feature:active:before {
  -webkit-transform: scaleY(1);
  transform: scaleY(1);
}

.single_feature i {
  border: 1px solid #e8e8e9;
  border-radius: 50%;
  color: #333;
  float: left;
  font-size: 20px;
  line-height: 60px;
  margin-right: 15px;
  position: relative;
  text-align: center;
  transition: all 0.3s ease 0s;
  width: 60px;
  z-index: 3;
  margin-top: 25px;
}

.single_feature:hover i {
  background: #fff;
  border: 1px solid #fff;
  color: #5f73a8;
}

.single_feature h3 {
  // text-transform: capitalize;
  font-size: 20px;
  font-weight: 400;
  margin: 0px 0px 10px;
  overflow: hidden;
}

.single_feature:hover h3 {
  color: #fff;
}

.single_feature span {
  border-bottom: 1px dashed #ccc;
  display: block;
  margin: 15px auto 10px;
  width: 80px;
}

.single_feature p {
  margin-bottom: 0;
  overflow: hidden;
}

.separator {
  position: relative;
  display: inline-block;
  text-transform: capitalize;
  margin-bottom: 30px;
}

.separator:after,
.separator:before {
  position: absolute;
  content: "";
  width: 50px;
  height: 2px;
  background: #5f73a8;
  top: 50%;
}

.separator i {
  color: #5f73a8;
}

.separator:after {
  right: 140%;
}

.separator:before {
  left: 140%;
}

.feature_icon {
  width: 120px !important;
  display: inline-table;
}

@media (max-width: 1120px) {
  .single_feature {
    height: 220px;
    padding: 30px;
  }

  .about-left h1{
    font-size: 30px;
  }
}

@media (max-width: 880px) {
  .single_feature {
    height: 230px;
    padding: 25px;
  }
  
}

@media (max-width: 767px) {
  .single_feature {
    height: 200px;
    padding: 25px;
    display: flex;
    align-items: center;
  }
}

@media (max-width: 525px) {
  .single_feature {
    height: 230px;
  }
}

@media (max-width: 475px) {
  .single_feature {
    display: block;
    height: auto;
  }

  .single_feature i {
    margin-top: 0;
    text-align: center;
  }
}
</style>

