<template>
  <div class="contact_bg">
    <div class="container">
      <div class="style">
        <p><span>#4 {{ $t("Contact") }}</span></p>
      </div>
      <div class="contact-form">
        <div class="info-section">
          <div class="info-item">
            <svg
              stroke="currentColor"
              fill="currentColor"
              stroke-width="0"
              viewBox="0 0 384 512"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
              class="icon icon1"
            >
              <path
                d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z"
              ></path>
            </svg>
            <div>
              <h3 class="info-title">{{ $t("address") }}</h3>
              <p class="info-details">{{ $t('region') }} {{ $t('district') }}</p>
            </div>
          </div>

          <div class="info-item">
            <svg
              stroke="currentColor"
              fill="currentColor"
              stroke-width="0"
              viewBox="0 0 512 512"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
              class="icon"
            >
              <path
                d="M497.39 361.8l-112-48a24 24 0 0 0-28 6.9l-49.6 60.6A370.66 370.66 0 0 1 130.6 204.11l60.6-49.6a23.94 23.94 0 0 0 6.9-28l-48-112A24.16 24.16 0 0 0 122.6.61l-104 24A24 24 0 0 0 0 48c0 256.5 207.9 464 464 464a24 24 0 0 0 23.4-18.6l24-104a24.29 24.29 0 0 0-14.01-27.6z"
              ></path>
            </svg>
            <div>
              <h3 class="info-title">{{ $t("PhoneNumber") }}</h3>
              <p class="info-details">
                +998 50 501 34 18 <br />
                +998 99 108 25 22
              </p>
            </div>
          </div>

          <div class="info-item">
            <svg
              stroke="currentColor"
              fill="currentColor"
              stroke-width="0"
              viewBox="0 0 24 24"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
              class="icon"
            >
              <path fill="none" d="M0 0h24v24H0z"></path>
              <path
                d="M22 8.98V18c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2h10.1c-.06.32-.1.66-.1 1 0 1.48.65 2.79 1.67 3.71L12 11 4 6v2l8 5 5.3-3.32c.54.2 1.1.32 1.7.32 1.13 0 2.16-.39 3-1.02zM16 5c0 1.66 1.34 3 3 3s3-1.34 3-3-1.34-3-3-3-3 1.34-3 3z"
              ></path>
            </svg>
            <div>
              <h3 class="info-title">{{ $t("Email") }}</h3>
              <p class="info-details">kuvvatovhusenbek@gmail.com</p>
            </div>
          </div>
        </div>

        <div class="form-section">
          <h2>{{ $t("sendMessage") }}</h2>
          <p>{{ $t("messagePrompt") }}</p>
          <form @submit.prevent="sendRequest" class="form">
            <input
              type="text"
              v-model="formData.firstName"
              :placeholder="$t('FirstName')"
              required
              id="firstName"
            />
            <input
              type="text"
              v-model="formData.lastName"
              :placeholder="$t('LastName')"
              required
              id="lastName"
            />
            <input
              type="text"
              v-model="formData.number"
              :placeholder="$t('PhoneNumber')"
              required
              id="phone"
            />
            <input
              type="email"
              v-model="formData.email"
              :placeholder="$t('Email')"
              required
              id="email"
            />
            <textarea
              v-model="formData.content"
              :placeholder="$t('Message')"
              required
              id="message"
            ></textarea>
            <button type="submit" class="send_button">
              {{ $t("sendButton") }}
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import axios from "axios";

export default {
  data() {
    return {
      formData: {
        firstName: "",
        lastName: "",
        number: "",
        email: "",
        content: "",
      },
    };
  },
  methods: {
    sendRequest() {
      const data = {
        firstName: this.formData.firstName,
        lastName: this.formData.lastName,
        number: this.formData.number,
        email: this.formData.email,
        content: this.formData.content,
      };

      axios
        .post("https://6611-94-232-25-180.ngrok-free.app/send", data)
        .then((response) => {
          console.log("Response:", response.data);
          alert("Message Sent Successfully!");
          this.formData = {
            firstName: "",
            lastName: "",
            number: "",
            email: "",
            content: "",
          };
        })
        .catch((error) => {
          console.error("There was an error!", error);
          alert("There was an error sending your message.");
        });
    },
  },
};
</script>
  
  <style scoped>
.contact_bg {
  background: #5f73a8;
  padding: 50px;
  margin: 0 200px;
  border-radius: 25px;
}
.contact-form {
  height: 550px;
  display: flex;
  width: 100%;
  background: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow: hidden;
  margin: 0 auto;
}
.info-section {
  background-color: #f4f4f4;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 400px;
  gap: 20px;
}
.info-item {
  text-align: center;
  gap: 15px;
  width: 100%;
}
.info-item h3 {
  text-align: center;
}
.icon {
  font-size: 40px;
  color: #5f73a8;
}
.info-title {
  font-weight: bold;
  margin: 0;
}
.info-details {
  margin: 0;
  font-size: 16px;
}
.style {
  border: 1px solid #95969f;
  border-radius: 20px;
  align-items: center;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  animation: fadeInUp 1.2s ease-out forwards;
  animation-delay: 1s;
  margin-bottom: 20px;
  padding: 0;
  box-sizing: border-box;
  background: #fff;
}
.style p {
  margin: 10px 20px;
}
.style span {
  border: 1px solid #5f73a8;
  padding: 0 10px;
  border-radius: 0 10px;
  text-align: center;
  color: #fff;
  
}
.form-section {
  flex: 2;
  padding-left: 50px;
  padding-right: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}
h2 {
  color: #333;
}
p {
  margin-top: 10px;
  margin-bottom: 20px;
  color: #555;
  font-size: 14px;
}
form {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
}
input,
textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 12px;
}
input:focus {
  border: 1px solid #5f73a8;
}
textarea {
  resize: none;
  height: 120px;
}
button {
  padding: 10px;
  border: none;
  background-color: #5f73a8;
  color: white;
  font-size: 12px;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}
button:hover {
  background-color: #47557d;
}

@media (max-width: 1390px) {
  .contact_bg {
    padding: 30px;
    margin: 0 15px;
  }
}

@media (max-width: 991px) {
  .info-section {
    width: 300px;
  }
  .info-item h3 {
    font-size: 20px;
  }

  .info-details {
    font-size: 14px;
  }
}

@media (max-width: 767px) {
  .info-section {
    width: 100%;
    padding: 30px 0;
  }

  .contact_bg {
    padding: 40px 10px;
  }

  .form{
    padding: 0 30px;
  }

  .contact-form {
    display: block;
    height: 100%;
  }

  .form-section{
    padding: 30px 0;
  }
}

@media (max-width: 480px) {
  .contact-form {
    flex-direction: column;
    padding: 20px;
    height: auto;
  }
  .form{
    padding: 0;
  }
  .info-section {
    flex: none;
    width: 100%;
    padding: 10px;
  }
  .form-section {
    flex: none;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 50px;
  }
  .info-item {
    gap: 10px;
  }
  .info-item h3 {
    font-size: 18px;
  }
  .icon {
    font-size: 30px;
  }
  .info-details {
    font-size: 14px;
  }
  .style {
    padding: 10px;
  }
  .style p {
    font-size: 18px;
    width: 100%;
    margin: 0 auto;
  }

  h2 {
    font-size: 22px;
    margin-bottom: 10px;
  }
  p {
    font-size: 12px;
  }
  input,
  textarea {
    padding: 12px;
    font-size: 14px;
  }
  button {
    padding: 12px;
    font-size: 12px;
    width: 100%;
  }
}
</style>
  