import { createRouter, createWebHistory } from 'vue-router'
import AppHeader from "@/layout/AppHeader.vue";
import AppFooter from "@/layout/AppFooter";
import Components from "../views/Components.vue";

const routes = [
  {
    path: "/",
    name: "components",
    meta: { redirectIfLoggedIn: true },
    components: {
      header: AppHeader,
      default: Components,
      footer: AppFooter,
    }
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),

  scrollBehavior: () => ({
    y: 0,
    x: 0,
  }),
  routes
})



router.beforeEach((to, from, next) => {
  if (to.path !== '/' && !to.meta.redirectIfLoggedIn) {
    if (localStorage.getItem('user_info')) {
      next();
    } else {
      next('/');
    }
  } else {
    next();
  }
});

export default router
