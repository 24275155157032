import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import i18n from './i18n';
import axios from 'axios';
import '@fortawesome/fontawesome-free/css/all.css';
import '@/assets/scss/global.scss';
import '@/assets/scss/variables.scss';


// Axios base URL
axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;

// Create the Vue app
const app = createApp(App);
app.use(store);
app.use(router);
app.use(i18n);


app.mount('#app');
