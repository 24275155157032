<template>
  <router-view name="header"></router-view>
  <!-- Header View -->
  <router-view></router-view>
  <!-- Default View -->
  <router-view name="footer"></router-view>
  <!-- Footer View -->
</template>

<script>
export default {
  name: 'App'
}
</script>

<style >
</style>