

<template>
  <div>
    <headervue></headervue>
    <we_project id="about_we"></we_project>
    <portofiol id="portfiol"></portofiol>
    <about_we></about_we>
    <contact_us id="contact"></contact_us>
  </div>
</template>

<script>
import headervue from "./header.vue";
import we_project from "./we_project.vue";
import about_we from "./about_we.vue";
import contact_us from "./contact_us.vue";
import portofiol from "./portofiol.vue";
export default {
  components: {
    headervue,
    we_project,
    about_we,
    contact_us,
    portofiol,
  },
};
</script>



<style>
</style>