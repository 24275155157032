<template>
  <div>
    <nav class="navbar">
      <div class="container">
        <div class="navbar-container">
          <div class="navbar-logo">
            <router-link to="/">
              <img class="logo" width="180" :src="Logo" alt="Company Logo" />
            </router-link>
          </div>

          <!-- Navigation Links -->
          <ul class="navbar-links">
            <li>
              <a href="/">{{ $t("Home") }}</a>
            </li>
            <li>
              <a href="#about_we">{{ $t("Services") }}</a>
            </li>
            <li>
              <a href="#portfiol">{{ $t("About Us") }}</a>
            </li>
            <li>
              <a href="#contact">{{ $t("Contact") }}</a>
            </li>

            <li>
              <div class="select">
                <label for="language-select" class="sr-only"></label>
                <select
                  class="selects"
                  v-model="selectedLanguage"
                  @change="changeLanguage"
                  id="language-select"
                >
                  <option class="options" value="uz">O'zbek</option>
                  <option class="options" value="en">English</option>
                  <option class="options" value="ru">Русский</option>
                </select>
              </div>
            </li>
          </ul>

          <!-- Hamburger Menu for Mobile -->
          <div
            class="btn navbar-toggle"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasRight"
            aria-controls="offcanvasRight"
            @click="toggleMenu"
          >
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </div>
    </nav>

    <div
      class="offcanvas offcanvas-end menu"
      tabindex="-1"
      id="offcanvasRight"
      aria-labelledby="offcanvasRightLabel"
    >
      <div class="offcanvas-header">
        <ul class="offcanvas-title" id="offcanvasRightLabel">
          <li>
            <div class="select">
              <label for="language-select" class="sr-only"></label>
              <select
                class="selects"
                v-model="selectedLanguage"
                @change="changeLanguage"
                id="language-select"
              >
                <option class="options" value="uz">O'zbek</option>
                <option class="options" value="en">English</option>
                <option class="options" value="ru">Русский</option>
              </select>
            </div>
          </li>
        </ul>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        ></button>
      </div>
      <div class="offcanvas-body">
        <ul>
          <li>
              <a href="/">{{ $t("Home") }}</a>
            </li>
            <li>
              <a href="#about_we">{{ $t("Services") }}</a>
            </li>
            <li>
              <a href="#portfiol">{{ $t("About Us") }}</a>
            </li>
            <li>
              <a href="#contact">{{ $t("Contact") }}</a>
            </li>
        </ul>
      </div>
    </div>
  </div>
</template>
  


<script setup>
import { computed } from "vue";
import { useStore } from "vuex"; // Import Vuex store
import Logo from "@/assets/images/loggo.png";
import { useI18n } from "vue-i18n"; // Import useI18n from vue-i18n

const store = useStore(); // Access Vuex store
const { t, locale } = useI18n(); // Access Vue I18n methods

const selectedLanguage = computed({
  get() {
    return store.getters.getSelectedLanguage; // Get the selected language from Vuex
  },
  set(newLang) {
    store.dispatch("changeLanguage", newLang); // Dispatch action to change the language in Vuex
    locale.value = newLang; // Update the i18n locale
    // Optionally, reload the page to apply language changes
    window.location.reload();
  },
});

const toggleMenu = () => {
  store.commit("toggleMenu");
};
</script>




  
  <style scoped>
/* Navbar styles */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #5f73a8;
  color: white;
  padding: 15px 0;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  /* border-bottom: 1px solid #ddd; */
}

.navbar-container {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

/* .navbar-logo a {
  color: white;
  text-decoration: none;
  font-size: 1.5rem;
  font-weight: bold;
} */

.navbar-links li a {
  text-decoration: none;
  color: white;
  font-size: 1rem;
  position: relative;
  cursor: pointer;
  transition: color 0.3s ease;
}
.navbar-links li a::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 3px;
  background-color: #fff;
  transition: width 0.3s ease;
}

.navbar-links li a:hover::before {
  width: 100%;
}

.navbar-links {
  font-size: 14px;
  cursor: pointer;

  list-style: none;
  display: flex;
  gap: 15px;
  color: #fff;
}

/* Mobile Styles */
.navbar-toggle {
  display: none;
  flex-direction: column;
  cursor: pointer;
  gap: 5px;
}

.navbar-toggle span {
  background-color: white;
  height: 3px;
  width: 25px;
  border-radius: 2px;
}

.is-active {
  display: flex !important;
}

.select {
  /* border: 1px solid #fff; */
  border-radius: 5px;
}
.menu {
  width: 60%;
}

.selects {
  border: none;
  padding: 5px;
  font-size: 16px;
  cursor: pointer;
}

#language-select {
  padding: 5px;
  font-size: 16px;
  cursor: pointer;
  border: none;

  /* background-color: #224DE7; */
  /* color: white; */
  border-radius: 5px;
}
@media (max-width: 768px) {
  .navbar-links {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 50px;
    right: 20px;
    background-color: #333;
    padding: 10px;
    border-radius: 5px;
  }

  .navbar-toggle {
    display: flex;
  }
}
</style>
  