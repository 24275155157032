<template>
  <div class="portfio_bg">
    <div class="container">
      <div class="text-center">
        <div class="style">
          <p><span>#3</span> {{ $t("Portfolio") }}</p>
        </div>

        <div>
          <p>
            Biz sizning biznesingizning o'ziga xos ehtiyojlari va talablariga
            asoslanib, eng samarali va innovatsion dasturiy yechimlarni
            yaratamiz. Bizning tajribamiz, bilimlarimiz va ilg'or
            texnologiyalarni qo'llash qobiliyatimiz sizga tez va sifatli
            dasturiy mahsulotni ishlab chiqish imkonini beradi. Biz sizning
            loyihangizni tezkor va samarali tarzda amalga oshirishga, vaqtni
            tejashga va byudjetni optimallashtirishga yordam beramiz. Bu esa
            sizga bozor talablariga tezda javob berishga va biznesingizni yanada
            rivojlantirishga yordam beradi.
          </p>
        </div>
      </div>

      <div class="mt-3">
        <!--start portfolio-->
        <div class="portfolio">
          <div class="container">
            <div class="tabs text-center">
              <input type="radio" value="all" id="all" name="tab" checked />
              <label for="all">Hammasi</label>

              <input
                type="radio"
                value="website design"
                id="websiteDesign"
                name="tab"
              />
              <label for="websiteDesign">Web ilova</label>

              <input
                type="radio"
                value="PHOTOGRAPHY"
                id="PHOTOGRAPHY"
                name="tab"
              />
              <label for="PHOTOGRAPHY">Mobil ilova</label>

              <div class="content">
                <div class="row">
                  <div class="all websiteDesign card_h col-sm-6 col-lg-3">
                    <div class="content">
                      <img
                        class="responsive-img"
                        src="../assets/images/portfilio.jpg"
                        alt="tab"
                      />
                      <div class="overlay">
                        <h3>SMART WARE</h3>
                        <p>{{ $t('Photography') }}</p>
                      </div>
                    </div>
                  </div>

                  <div class="all PHOTOGRAPHY card_h col-sm-6 col-lg-3">
                    <div class="content">
                      <img
                        class="responsive-img"
                        src="../assets/images/portfilio2.jpg"
                        alt="tab"
                      />
                      <div class="overlay">
                        <h3>MEBELSAVDO</h3>
                        <p>{{ $t('mobil') }}</p>
                      </div>
                    </div>
                  </div>
                  <div class="all PHOTOGRAPHY card_h col-sm-6 col-lg-3">
                    <div class="content">
                      <img
                        class="responsive-img"
                        src="../assets/images/portfilio5.jpg"
                        alt="tab"
                      />
                      <div class="overlay">
                        <h3>MEBELSAVDO</h3>
                        <p>{{ $t('mobil') }}</p>
                      </div>
                    </div>
                  </div>

                  <div class="all websiteDesign card_h col-sm-6 col-lg-3">
                    <div class="content">
                      <img
                        class="responsive-img"
                        src="../assets/images/portfilio1.jpg"
                        alt="tab"
                      />
                      <div class="overlay">
                        <h3>MEBELSAVDO</h3>
                        <p>{{ $t('Photography') }}</p>
                      </div>
                    </div>
                  </div>

                  <div class="all websiteDesign card_h col-sm-6 col-lg-3">
                    <div class="content">
                      <img
                        class="responsive-img"
                        src="../assets/images/portfilio4.jpg"
                        alt="tab"
                      />
                      <div class="overlay">
                        <h3>XURTOUR</h3>
                        <p>{{ $t('Photography') }}</p>
                      </div>
                    </div>
                  </div>

                  <div class="all PHOTOGRAPHY card_h col-sm-6 col-lg-3">
                    <div class="content">
                      <img
                        class="responsive-img"
                        src="../assets/images/portfilio6.jpg"
                        alt="tab"
                      />
                      <div class="overlay">
                        <h3>MEBELSAVDO</h3>
                        <p>{{ $t('mobil') }}</p>
                      </div>
                    </div>
                  </div>
                  <div class="all PHOTOGRAPHY card_h col-sm-6 col-lg-3">
                    <div class="content">
                      <img
                        class="responsive-img"
                        src="../assets/images/portfilio3.jpg"
                        alt="tab"
                      />
                      <div class="overlay">
                        <h3>MEBELSAVDO</h3>
                        <p>{{ $t('mobil') }}</p>
                      </div>
                    </div>
                  </div>

                  <div class="all websiteDesign card_h col-sm-6 col-lg-3">
                    <div class="content">
                      <img
                        class="responsive-img"
                        src="../assets/images/portfilio7.jpg"
                        alt="tab"
                      />
                      <div class="overlay">
                        <h3>SMART WARE</h3>
                        <p>{{ $t('Photography') }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.title {
  font-size: 48px;

  font-weight: bold;
}

.style {
  border: 1px solid #95969f;
  border-radius: 20px;
  align-items: center;
  text-align: center;
  padding: 10px 30px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  opacity: 1;
  animation: fadeInUp 1s ease-out forwards;
  animation-delay: 1s;
  margin-bottom: 40px;
}

.style span {
  border: 1px solid #5f73a8;
  padding: 0 10px;
  border-radius: 0 10px;
  text-align: center;
  color: #fff;
  background: #5f73a8;
}

.portfio_bg {
  margin: 50px 0;
}

/*start portfolio*/
.portfolio p {
  color: rgba(255, 255, 255, 0.6);
  margin: 20px 40px;
  letter-spacing: 1px;
  line-height: 1.5em;
}

/*start tabs*/
.portfolio input[type="radio"] {
  display: none;
}
.portfolio .tabs label {
  display: inline-block;
  color: #3f4244;
  text-transform: uppercase;
  text-align: center;
  padding: 10px 18px;
  margin-bottom: 20px;
  cursor: pointer;
}
.portfolio .tabs .all {
  display: none;
  transform-origin: 10% 50%;
}
.portfolio .tabs .content {
  margin-bottom: 29px;

  position: relative;
  overflow: hidden;
}
.portfolio .tabs .content img {
  height: 190px;
  width: 100%;
}

.portfolio .tabs label:not(:first-of-type) {
  margin-left: -4px;
}
.portfolio input[id="all"]:checked ~ .content .all {
  display: block;
  animation: animateTabe 1s;
}
.portfolio input[id="websiteDesign"]:checked ~ .content .websiteDesign {
  display: block;
  animation: animateTabe 1s;
}
.portfolio input[id="PHOTOGRAPHY"]:checked ~ .content .PHOTOGRAPHY {
  display: block;
  animation: animateTabe 1s;
}
// .portfolio input[id="graphicDesign"]:checked ~ .content .graphicDesign {
//   display: block;
//   animation: animateTabe 1s;
// }
.portfolio input[type="radio"]:checked + label {
  background-color: transparent;
  border-radius: 25px;
  border: 1px solid #5f73a8;
  cursor: default;
}
.portfolio .tabs .overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 100%;
  padding-top: 10%;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  background: rgba(0, 0, 0, 0.5);
  left: 0;
  transition: all 0.5s;
  transition: all 0.5s linear;
}
.portfolio .tabs .all .content:hover .overlay {
  top: 0;
}
.portfolio .tabs .overlay span {
  padding: 2px 8px;
  border: 1px solid #fff;
  border-radius: 3px;
  cursor: pointer;
}
.portfolio .tabs .overlay span:hover {
  background: #5cc05c;
  border-color: #5cc05c;
}
@keyframes animateTabe {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.classic-list {
  list-style-type: none;
}

@media (max-width: 991px) {
  .portfolio .tabs .content img {
    width: 100%;
    height: 100%;
  }
}
</style>