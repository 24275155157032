<template>
  <div class="header_bg">
    <div class="container">
      <div class="header_top">
        <div class="main-left">
          <div class="style">
            <p><span>#1</span> {{ t("Home") }}</p>
          </div>
          <div class="about-left">
            <h1>{{ $t('header_title') }}</h1>
            <div class="home-btn">
              <a href="#about_we">
                <button class="btn-primary">{{ t("Nima qila olamiz") }}</button>
              </a>
             <a href="#contact">
              <button class="btn-secondary">{{ t("Bog'lanish") }}</button>
             </a>
            </div>
          </div>
        </div>
        <div class="main-right">
          <img width="650" :src="FirstImg" alt="Technology" loading="lazy" />
        </div>
      </div>
    </div>
  </div>
</template>
  
<script setup>
import { useI18n } from "vue-i18n";

import FirstImg from "@/assets/images/header4.jpg";
const { t } = useI18n();
</script>
  
  <style scoped>
.header_top {
  width: 100%;
  display: flex;
  align-content: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 160px;

}

.main-right {
  width: 50%;
}

.main-left{
  width: 50%;

}

.style {
  border: 1px solid #95969f;
  border-radius: 20px;
  align-items: center;
  text-align: center;
  padding: 10px 30px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  opacity: 0;
  animation: fadeInUp 1s ease-out forwards;
  animation-delay: 1s;
}



.style span {
  border: 1px solid #5f73a8;
  padding: 0 10px;
  border-radius: 0 10px;
  text-align: center;
  color: #fff;
  background: #5f73a8;
}

.about-left h1 {
  font-size: 50px;
  line-height: 1.3;
  margin-bottom: 20px;
  text-align: center;
  opacity: 0;
  transform: translateY(30px);
  animation: fadeInUp 1s ease-out forwards;
  animation-delay: 0.5s;
}

/* Paragraph Animation */
.about-left p {
  font-size: 16px;
  text-align: center;
  color: #92969f;
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 1s ease-out forwards;
  animation-delay: 1.5s; /* Adjusted animation delay */
}

.home-btn {
  display: flex;
  justify-content: center;
  gap: 20px;
  padding: 20px 10px;
}

.btn-primary,
.btn-secondary {
  padding: 12px 30px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 50px;
  border: none;
  outline: none;
  transition: transform 0.3s ease, background-color 0.3s ease,
    box-shadow 0.3s ease, color 0.3s ease;
}

.btn-primary {
  background-color: #5f73a8;
  color: white;
  /* box-shadow: 0 4px 8px rgba(34, 77, 231, 0.2); */
}

.btn-primary:hover {
  background-color: #5f73a8;
  /* box-shadow: 0 6px 12px rgba(34, 77, 231, 0.3); */
  /* transform: scale(1.05); */
}

.btn-secondary {
  background-color: transparent;
  color: #5f73a8;
  border: 2px solid #5f73a8;
  /* box-shadow: 0 4px 8px rgba(34, 77, 231, 0.2); */
}

.btn-secondary:hover {
  background-color: #5f73a8;
  color: white;
  /* box-shadow: 0 6px 12px rgba(34, 77, 231, 0.3); */
  /* transform: scale(1.05); */
}

/* Keyframe Animations */
@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* @keyframes fadeInContainer {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  } */

  @media (max-width: 480px) {
    .header_top {
      flex-direction: column;
      margin-top: 100px;
      text-align: center;
    }
  
    .main-left, .main-right {
      width: 100%;
      padding: 0 20px;
      text-align: center;
    }
  
    .main-right img {
      width: 100%;
      height: auto;
      max-width: 400px;
      margin-top: 20px;
    }
  
    .style {
      padding: 10px 20px;
      margin-top: 40px;
    }
    .style p {
      font-size: 18px;
    }
  
    .about-left h1 {
      font-size: 36px;
    }
  
    .home-btn {
      display: flex;
      flex-direction: column;
      gap: 15px;
      margin-top: 20px;
    }
  
    .btn-primary, .btn-secondary {
      padding: 12px 25px;
      font-size: 16px;
      width: 100%;
    }
  }
  
  /* iPad (tablet) version */
  @media (min-width: 481px) and (max-width: 1024px) {
    .header_top {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  
    .main-left, .main-right {
      width: 48%;
    }
  
    .main-right img {
      width: 100%;
    }
  
    .style {
      padding: 12px 25px;
    }
  
    .about-left h1 {
      font-size: 40px;
    }
  
    .home-btn {
      display: flex;
      flex-direction: column;
      gap: 15px;
      margin-top: 20px;
    }
  
    .btn-primary, .btn-secondary {
      padding: 12px 30px;
      font-size: 16px;
    }
  }
</style>
  